import React from "react";

// Component imports
import PainLoggerLayout from "../../components/pain_logger/PainLoggerLayout";
import Construction from "../../components/common/Construction";
import SEO from "../../components/common/seo";


// Image imports

// CSS imports

export default ({ data }) => {
  return (
    <PainLoggerLayout>
      <SEO title="Pain Logger Press" keywords={[`pain`, `painlogger`, `press`]} />
      <Construction 
        title="Pain Logger Press" 
        backPage="pain_logger"
        backButtonTitle="Back"/>
    </PainLoggerLayout>
  );
};
